import React, { useEffect, useState } from "react";
import { ApiServiceClass } from "../components/api-service.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./navbar.js";
import {
  Box,
  Button,
  Card,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import DuplicateIcon from "@mui/icons-material/ContentCopy";
import PushNotificationForm from "../components/push-notification-new.js";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import QuestionMarkIcon from "@heroicons/react/24/solid/QuestionMarkCircleIcon";
import { CancelPushNotification } from "../components/cancel-push-notification.js";
import { toast } from "react-toastify";

export default function MarketingPushNotifications() {
  const navigate = useNavigate();
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);

  const [addOpen, setAddOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [marketingCredits, setMarketingCredits] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [customerGroup, setCustomerGroup] = useState(1);
  const [loadingPushCost, setLoadingPushCost] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);

  const [pushCostData, setPushCostData] = useState({
    NumUsers: 0,
    NumCredits: 0,
  });

  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
        RequesterUserId: loginData.UserId,
      };
      console.log(requestBody);
      const data = await ApiServiceClass.GetPushNotifications(requestBody);
      console.log(data);
      // Filter the data, only keeping the first occurrence of each GroupId
      const seenGroupIds = new Set();
      const uniqueNotifications = data.filter((notification) => {
        if (!seenGroupIds.has(notification.GroupId)) {
          seenGroupIds.add(notification.GroupId);
          return true; // Keep this notification
        }
        return false; // Skip duplicate GroupIds
      });

      // Set the filtered data
      setNotifications(uniqueNotifications);
      loadMarketingCredits();
      setLoading(false);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }

    try {
      var requestBody = {
        CompanyId: companyDetails.Id,
      };
      const paymentData = await ApiServiceClass.GetSubscription(requestBody);
      setActiveSubscription(paymentData.IsSubscriptionActive);
      console.log(paymentData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMarketingCredits = async () => {
    try {
      // Prepare the request body
      const requestBody = {
        CompanyId: companyDetails.Id,
      };
      console.log("Requesting marketing credits:", requestBody);

      // Await the API call and store the result
      const data = await ApiServiceClass.GetMarketingCredits(requestBody);

      // Check if the data is returned in the expected format
      if (
        data &&
        data.RemainingCredits !== undefined &&
        data.PlanCredits !== undefined
      ) {
        // Set marketingCredits to the response data
        setMarketingCredits(data);
      } else {
        console.error("Unexpected data format:", data);
      }

      console.log("Marketing credits data:", data);
    } catch (error) {
      console.error("Error fetching marketing credits:", error);
    }
  };

  const getCreatePushNotifCost = async () => {
    try {
      setLoadingPushCost(true); // Start loading
      const requestBody = {
        CompanyId: companyDetails.Id,
        CustomerGroup: 1,
      };
      console.log(requestBody);

      const data = await ApiServiceClass.GetCreatePushNotifCost(requestBody);
      setPushCostData(data);
      console.log("fdsfsdafsd", data);
    } catch (error) {
      console.error("Error fetching push cost:", error);
    } finally {
      setLoadingPushCost(false); // Stop loading
    }
  };

  const handleAddOpen = async (notif = null) => {
    if (!activeSubscription) {
      toast.error("Subscribe to unlock this feature");
      return;
    }
    if (notif) {
      // setTitle(notif.Title);
      setMessage(notif.Message);
      setCustomerGroup(notif.CustomerGroup);
    } else {
      // setTitle("");
      setMessage("");
      setCustomerGroup(1);
    }
    await getCreatePushNotifCost();

    setAddOpen(true);
  };

  const handleAddClose = () => {
    setAddOpen(false);
  };

  // Helper function to get label for customerGroup
  const getGroupLabel = (customerGroup) => {
    switch (customerGroup) {
      case 1:
        return "All Users";
      case 2:
        return "Active Users";
      case 3:
        return "Inactive Users";
      case 4:
        return "Users that recently claimed rewards";
      default:
        return "Unknown Group";
    }
  };

  // Helper function for status coloring
  const getStatusStyle = (MarkedForDelete, status) => ({
    color: MarkedForDelete ? "red" : status ? "green" : "blue",
  });

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function formatDateString(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define options for formatting
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Format the date using toLocaleString
    const formattedDate = date
      .toLocaleString("en-GB", options)
      .replace(",", "");

    return formattedDate.toLowerCase();
  }

  // Filter notifications based on active tab
  const filteredNotifications = notifications.filter((notif) => {
    if (activeTab === 0) return true; // All notifications
    if (activeTab === 1) return !notif.HasBeenSent && !notif.MarkedForDelete; // Pending notifications
    if (activeTab === 2) return notif.HasBeenSent; // Sent notifications
    return true;
  });

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Navbar name="marketing-push-notifications" title="Push Notifications">
      {/* Title for Push Notifications */}
      <Typography variant="h4" sx={{ marginLeft: 2, marginTop: 4 }}>
        Push Notifications
      </Typography>

      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 2,
        }}
      >
        {/* Tabs Section */}
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="All" />
          <Tab label="Pending" />
          <Tab label="Sent" />
        </Tabs>

        {/* New Push Notification Button */}
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PlusIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={() => handleAddOpen()}
          sx={{ marginLeft: 2 }}
        >
          New Push Notification
        </Button>
      </Box>

      {/* Notification History Table */}
      <Card sx={{ marginTop: 2 }}>
        {/* Additional Text Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: 2,
            marginLeft: "1%",
          }}
        >
          <Typography variant="body1" sx={{ fontSize: "12px" }}>
            Credits: {marketingCredits.RemainingCredits}/
            {marketingCredits.PlanCredits}
          </Typography>
          <Tooltip title="Credits reset at the end of each payment month" arrow>
            <span>
              <QuestionMarkIcon
                style={{
                  marginBottom: "4px",
                  marginLeft: "4px", // Space between text and icon
                  cursor: "help",
                  width: 20,
                  height: 20,
                  color: "#c6c8cc",
                  verticalAlign: "middle", // Ensure vertical alignment
                  display: "inline-flex", // Ensure icon is inline and can center properly
                  alignItems: "center", // Center icon vertically
                }}
              />
            </span>
          </Tooltip>
        </Box>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell>Actions</TableCell>
              {/* <TableCell>Title</TableCell> */}
              <TableCell>Message</TableCell>
              <TableCell>Customer Group</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Scheduled</TableCell>
              <TableCell>
                CTR
                <Tooltip title="Click Through Rate (CTR)" arrow>
                  <span>
                    <QuestionMarkIcon
                      style={{
                        marginBottom: "4px",
                        marginLeft: "4px", // Space between text and icon
                        cursor: "help",
                        width: 20,
                        height: 20,
                        color: "#c6c8cc",
                        verticalAlign: "middle", // Ensure vertical alignment
                        display: "inline-flex", // Ensure icon is inline and can center properly
                        alignItems: "center", // Center icon vertically
                      }}
                    />
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredNotifications.map((notif, index) => (
              <TableRow hover key={index}>
                <TableCell style={{ width: "270px" }}>
                  <Button
                    startIcon={
                      <SvgIcon fontSize="small">
                        <DuplicateIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                    size="small"
                    onClick={() => handleAddOpen(notif)}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                  >
                    Duplicate
                  </Button>

                  {!notif.HasBeenSent && !notif.MarkedForDelete && (
                    <CancelPushNotification
                      companyId={companyDetails.Id}
                      notification={notif}
                      fetchData={fetchData}
                    />
                  )}
                </TableCell>
                {/* <TableCell>{notif.Title}</TableCell> */}
                <TableCell>{notif.Message}</TableCell>
                <TableCell>{getGroupLabel(notif.CustomerGroup)}</TableCell>
                <TableCell>{formatDateString(notif.CreatedDateTime)}</TableCell>
                <TableCell
                  sx={getStatusStyle(notif.MarkedForDelete, notif.HasBeenSent)}
                >
                  {notif.MarkedForDelete
                    ? "Cancelled"
                    : notif.HasBeenSent
                    ? "Sent"
                    : "Pending"}
                </TableCell>
                <TableCell>{formatDateString(notif.ScheduledTime)}</TableCell>
                <TableCell>{notif.CTR}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      {/* New notification form if open */}
      {addOpen && !loadingPushCost && (
        <PushNotificationForm
          addOpen={addOpen}
          handleAddClose={handleAddClose}
          initialMessage={message}
          initialCustomerGroup={customerGroup}
          companyId={companyDetails.Id}
          loginUserId={loginData.UserId}
          marketingCredits={marketingCredits}
          setCustomerGroup={setCustomerGroup}
          fetchData={fetchData}
          pushCostData={pushCostData}
        />
      )}

      {addOpen && loadingPushCost && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Navbar>
  );
}

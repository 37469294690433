import React, { useState } from "react";
import { Button } from "@mui/material";
import { SketchPicker } from "react-color";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import ThemeSelector from "../cards/ThemeSelector";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { ApiServiceClass } from "../../../components/api-service";

function ThemesContent({ fonts, designManager }) {
  const [selectedTheme, setSelectedTheme] = useState();
  const [themeContent, setThemeContent] = useState({});
  console.log("All sample themes: ", designManager.getAllSampleThemes());
  const handleThemeSelect = (themeId) => {
    setSelectedTheme(themeId);
    console.log("Selected Theme ID:", themeId);
    const theme = designManager.getSampleTheme(themeId);
    if (theme != null) {
      const themeMappings = {
        appHeaderBackgroundColor: theme.AppHeaderBackgroundColour,
        appHeaderFont: theme.AppHeaderFont,
        appHeaderFontColor: theme.AppHeaderFontColour,

        buttonBackgroundColor: theme.ButtonBackgroundColour,
        buttonFontColor: theme.ButtonFontColour,
        buttonsFont: theme.ButtonFont,

        companyLogo: theme.CompanyLogo,

        loyaltyCardBackgroundColor: theme.LoyaltyCardBackgroundColour,
        loyaltyCardTextColor: theme.LoyaltyCardTextColour,

        navBarBackgroundColor: theme.NavigationMenuBackgroundColour,
        navBarDeselectedColor: theme.NavigationMenuDeselectedColour,
        navBarSelectedColor: theme.NavigationMenuSelectedColour,

        primaryBackgroundColor: theme.PrimaryBrandBackgroundColour,
        primaryFontColor: theme.PrimaryBrandFontColour,
        primaryThemeFont: theme.PrimaryBrandFont,

        rewardSchemePanelBackgroundColor:
          theme.RewardSchemePanelBackgroundColour,
        rewardSchemePurchasesRequired: theme.PurchasesRequired,
        rewardSchemeStampBackgroundColor:
          theme.RewardSchemeStampBackgroundColour,
        rewardSchemeStampIcon: theme.RewardStamp,
        rewardSchemeSubtitle: theme.RewardSubTitle,
        rewardSchemeTextColor: theme.RewardSchemeTextColor,
        rewardSchemeTitle: theme.RewardTitle,

        splashScreenBackgroundColor: theme.BackgroundSplashColour,
        splashScreenLogo: theme.SplashLogo,
      };
      // Apply all theme properties
      Object.entries(themeMappings).forEach(
        ([contentProperty, newProperty]) => {
          designManager.setUnsavedChanges({ [contentProperty]: newProperty });
          setThemeContent((prevContent) => ({
            ...prevContent,
            [contentProperty]: newProperty,
          }));
        }
      );
    }
  };

  const getFontIdByTechnicalName = (technicalName) => {
    const font = fonts.find((font) => font.FontTechnicalName === technicalName);
    return font ? font.Id : null; // Return the Id if found, otherwise return null
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();
    setSelectedTheme(null);
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
        CompanyId: designManager.getCompanyId(),
        AppHeaderBackgroundColour: updatedDesign.appHeaderBackgroundColor,
        AppHeaderFontColour: updatedDesign.appHeaderFontColor,
        AppHeaderFontStyle: getFontIdByTechnicalName(
          updatedDesign.appHeaderFont
        ),
        AppHeaderFontColour: updatedDesign.appHeaderFontColor,
        PrimaryBrandBackgroundColour: updatedDesign.primaryBackgroundColor,
        PrimaryBrandFontColour: updatedDesign.primaryFontColor,
        ButtonsBackgroundColour: updatedDesign.buttonBackgroundColor,
        ButtonsFontColour: updatedDesign.buttonFontColor,
        ButtonsFontStyle: getFontIdByTechnicalName(updatedDesign.buttonsFont),
        NavigationMenuSelectedColour: updatedDesign.navBarSelectedColor,
        NavigationMenuDeselectedColour: updatedDesign.navBarDeselectedColor,
        NavigationMenuBackgroundColour: updatedDesign.navBarBackgroundColor,
        LoyaltyCardBackgroundColour: updatedDesign.loyaltyCardBackgroundColor,
        LoyaltyCardTextColour: updatedDesign.loyaltyCardTextColor,
        PrimaryBrandFontStyle: getFontIdByTechnicalName(
          updatedDesign.primaryThemeFont
        ),
        RewardSchemePanelBackgroundColour:
          updatedDesign.rewardSchemePanelBackgroundColor,
        RewardSchemeStampBorderColour:
          updatedDesign.rewardSchemeStampBackgroundColor,
        RewardSchemeTextColour: updatedDesign.rewardSchemeTextColor,
        CompanyLogo: updatedDesign.companyLogo,
        CompanyLogoFileType: 1,
        SplashLogo: updatedDesign.splashScreenLogo,
        SplashLogoFileType: 1,
        SplashScreenBackgroundColour: updatedDesign.splashScreenBackgroundColor,
        RewardStamp: updatedDesign.rewardSchemeStampIcon,
        RewardTitle: updatedDesign.rewardSchemeTitle,
        RewardSubTitle: updatedDesign.rewardSchemeSubtitle,
        NumberOfPurchasesRequired: updatedDesign.rewardSchemePurchasesRequired,
        FromWeb: true,
      };
      console.log("Calling SaveFullTheme API:", requestBody);
      const response = ApiServiceClass.SaveFullTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Company Theme Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Saving Company Theme: ", error);
          toast.error("Error Saving Company Theme", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  return (
    <>
      <div>
        <div style={Styles.panel}>
          <div style={Styles.contentWrapper}>
            <div style={Styles.content}>
              <ThemeSelector
                selectedTheme={selectedTheme}
                onThemeSelect={handleThemeSelect}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
}

export default ThemesContent;

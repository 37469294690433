import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./navbar.js";
import DesignSidePanel from "../components/myapp/designsidepanel.js";
import AppDesignRenderer from "../components/myapp/appdesignrenderer.js";
import iPhoneImage from "../components/myapp/resources/iphone2.png";
import navigationIcon from "../components/myapp/resources/navigation.png";
import { ApiServiceClass } from "../components/api-service";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Importing missing icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GettingStarted from "../components/myapp/cards/sharedcomponents/GettingStarted.js";

import {
  Box,
  Typography,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import VideoGuide from "../components/myapp/cards/sharedcomponents/VideoGuide.js";

export default function MyApp() {
  const [width, setWidth] = useState("20%");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1720);
  const [availableFonts, setAvailableFonts] = useState([]);
  const rawLoginData = localStorage.getItem("loginData");
  const loginData = JSON.parse(rawLoginData);
  const rawCompanyDetails = localStorage.getItem("companyData");
  const companyDetails = JSON.parse(rawCompanyDetails);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [popoverAnchorNav, setPopoverAnchorNav] = useState(null); // Renamed from anchorEl to popoverAnchor
  const [popoverAnchorHelp, setPopoverAnchorHelp] = useState(null); // Renamed from anchorEl to popoverAnchor
  const openNav = Boolean(popoverAnchorNav); // Update to check if popoverAnchor is not null
  const openHelp = Boolean(popoverAnchorHelp); // Update to check if popoverAnchor is not null
  const [isHelpOpen, setHelpOpen] = useState(false); // State for WarningBox
  const [activeHelpItem, setActiveHelpItem] = useState(null); // To track which help item is active
  const [activeTab, setActiveTab] = useState("home"); // Add this line

  const [designManager] = useState(() => ({
    design: {},
    unsavedChanges: {},
    listeners: [],
    companyId: null,
    userId: null,
    sampleThemes: null, // Initialize as null

    getCurrentDesign() {
      return this.design;
    },

    getUnsavedDesign() {
      return { ...this.design, ...this.unsavedChanges };
    },

    getCompanyId() {
      return this.companyId;
    },

    getUserId() {
      return this.userId;
    },

    updateDesign(newDesign) {
      this.design = { ...this.design, ...newDesign };
      this.unsavedChanges = {};
      this.notifyListeners();
    },

    setUnsavedChanges(changes) {
      this.unsavedChanges = { ...this.unsavedChanges, ...changes };
      this.notifyListeners();
    },

    resetUnsavedChanges() {
      this.unsavedChanges = {};
      this.notifyListeners();
    },

    hasUnsavedChanges() {
      return Object.keys(this.unsavedChanges).length > 0;
    },

    addChangeListener(callback) {
      this.listeners.push(callback);
    },

    removeChangeListener(callback) {
      this.listeners = this.listeners.filter(
        (listener) => listener !== callback
      );
    },

    notifyListeners() {
      this.listeners.forEach((listener) => listener());
    },

    // New method to get field value considering unsaved changes
    getFieldValue(fieldName) {
      let value =
        this.unsavedChanges[fieldName] ?? this.design[fieldName] ?? "#000000"; // Use nullish coalescing to handle the logic
      let source;

      if (this.unsavedChanges.hasOwnProperty(fieldName)) {
        source = "unsaved changes";
      } else if (fieldName in this.design) {
        source = "saved design";
      } else {
        source = "default source"; // No matching field found
      }

      // Log the result once, with the determined source
      // console.log(
      //   `Searched for "${fieldName}" and found "${value}" from ${source}.`
      // );

      return value;
    },

    getAllSampleThemes() {
      return this.sampleThemes;
    },

    getSampleTheme(themeId) {
      // Convert themeId (1-based) to array index (0-based)
      const index = themeId;

      if (index >= 0 && index < this.sampleThemes?.length) {
        return this.sampleThemes[index]; // Access the theme by index
      }

      console.warn(`Theme with ID ${themeId} not found. Returning null.`);
      return null;
    },
  }));

  useEffect(() => {
    if (!companyDetails || !loginData) {
      console.log("Redirecting to /Login");
      navigate("/Login");
    }
  }, [companyDetails, loginData, navigate]);

  useEffect(() => {
    const handleResize = () => {
      const isLarge = window.innerWidth >= 1720;
      setIsLargeScreen(isLarge);
    };

    // Debouncing the resize event
    const debounceResize = () => {
      let resizeTimeout;
      return () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(handleResize, 200);
      };
    };

    const debouncedResizeHandler = debounceResize();

    // Initial check
    handleResize();

    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, []);

  const handleWidthChange = (newWidth) => {
    setWidth(newWidth);
  };

  useEffect(() => {
    if (companyDetails === null || loginData === null) {
      console.log("redirecting to /Login");
      navigate("/Login");
    }
    designManager.companyId = companyDetails.Id;
    designManager.userId = loginData.UserId;
    console.log(
      "fetching design apis for company: ",
      designManager.getCompanyId()
    );

    var requestBody = {
      CompanyId: designManager.getCompanyId(),
    };

    var requestBodyFromWeb = {
      CompanyId: designManager.getCompanyId(),
      FromWeb: true,
    };

    const fetchData = async () => {
      console.log(designManager.getCompanyId());
      try {
        console.log(fetchData);
        const fontsResponse = await ApiServiceClass.GetAvailableFonts();
        const sortedFonts = [...fontsResponse].sort((a, b) =>
          a.FontFriendlyName.localeCompare(b.FontFriendlyName)
        );
        setAvailableFonts(sortedFonts);
        console.log(availableFonts);

        const navigationThemeResponse =
          await ApiServiceClass.GetMenuNavigationTheme(requestBody);
        const loyaltyResponse = await ApiServiceClass.GetLoyaltyCardTheme(
          requestBody
        );
        const splashThemeResponse = await ApiServiceClass.GetSplashTheme(
          requestBodyFromWeb
        );
        const companyLogoResponse = await ApiServiceClass.GetCompanyLogo(
          requestBodyFromWeb
        );
        const fontsThemeResponse = await ApiServiceClass.GetFontsTheme(
          requestBody
        );
        const buttonsResponse = await ApiServiceClass.GetButtonsTheme(
          requestBody
        );
        const colorsResponse = await ApiServiceClass.GetColorsTheme(
          requestBody
        );
        const rewardResponse = await ApiServiceClass.GetRewardSchemeTheme(
          requestBodyFromWeb
        );
        const sampleThemesResponse = await ApiServiceClass.GetSampleThemes(
          requestBody
        );

        initaliseDesignManager(
          navigationThemeResponse,
          loyaltyResponse,
          splashThemeResponse,
          companyLogoResponse,
          fontsThemeResponse,
          buttonsResponse,
          colorsResponse,
          rewardResponse,
          sampleThemesResponse
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const initaliseDesignManager = (
    navigationThemeResponse,
    loyaltyResponse,
    splashThemeResponse,
    companyLogoResponse,
    fontsThemeResponse,
    buttonsResponse,
    colorsResponse,
    rewardResponse,
    sampleThemesResponse
  ) => {
    const responses = [
      navigationThemeResponse,
      loyaltyResponse,
      splashThemeResponse,
      companyLogoResponse,
      fontsThemeResponse,
      buttonsResponse,
      colorsResponse,
      rewardResponse,
      sampleThemesResponse,
    ];
    designManager.sampleThemes = JSON.parse(sampleThemesResponse.text);

    responses.forEach((response) => {
      if (response.status === 200) {
        // Handle the successful response here
        console.log("Response Body:", JSON.stringify(response.text, null, 2)); // Log the response body formatted
      } else {
        console.error(
          "Error Details:",
          response.error || "No error details provided"
        ); // Log error details if available
      }
    });

    try {
      // Parse the JSON string in the 'text' field
      const navigationTheme = JSON.parse(navigationThemeResponse.text);
      const loyaltyTheme = JSON.parse(loyaltyResponse.text);
      const splashTheme = JSON.parse(splashThemeResponse.text);
      const companyLogoTheme = JSON.parse(companyLogoResponse.text);
      const fontsTheme = JSON.parse(fontsThemeResponse.text);
      const buttonsTheme = JSON.parse(buttonsResponse.text);
      const colorsTheme = JSON.parse(colorsResponse.text);
      const rewardTheme = JSON.parse(rewardResponse.text);

      // Update design manager with new theme
      designManager.updateDesign({
        navBarBackgroundColor: navigationTheme.NavBarBackgroundColor,
        navBarSelectedColor: navigationTheme.NavBarSelectedColor,
        navBarDeselectedColor: navigationTheme.NavBarUnselectedColor,
        loyaltyCardBackgroundColor: loyaltyTheme.BackgroundColor,
        loyaltyCardTextColor: loyaltyTheme.TextColor,
        companyLogo: companyLogoTheme.CompanyLogo,
        companyLogoFileType: companyLogoTheme.LogoFileType,
        splashScreenBackgroundColor: splashTheme.BackgroundColor,
        splashScreenLogo: splashTheme.SplashLogo,
        splashScreenLogoFileType: splashTheme.LogoFileType,

        appHeaderFont: fontsTheme.AppHeaderFont,
        primaryThemeFont: fontsTheme.PrimaryFont,
        buttonsFont: fontsTheme.ButtonsFont,

        buttonBackgroundColor: buttonsTheme.ButtonsBackgroundColour,
        buttonFontColor: buttonsTheme.ButtonsFontColour,
        buttonsFont: buttonsTheme.ButtonsFont,

        appHeaderBackgroundColor: colorsTheme.AppHeaderBackgroundColour,
        appHeaderFontColor: colorsTheme.AppHeaderFontColour,
        primaryBackgroundColor: colorsTheme.PrimaryBrandBackgroundColour,
        primaryFontColor: colorsTheme.PrimaryBrandFontColour,
        navBarBackgroundColor: colorsTheme.NavigationMenuBackgroundColour,
        navBarSelectedColor: colorsTheme.NavigationMenuSelectedColour,
        navBarDeselectedColor: colorsTheme.NavigationMenuDeselectedColour,
        splashScreenBackgroundColor: colorsTheme.SplashScreenBackgroundColour,
        buttonBackgroundColor: buttonsTheme.ButtonsBackgroundColour,
        buttonFontColor: buttonsTheme.ButtonsFontColour,
        loyaltyCardBackgroundColor:
          colorsTheme.DigitalLoyaltyCardBackgroundColour,
        loyaltyCardTextColor: colorsTheme.DigitalLoyaltyCardTextColour,
        rewardSchemeTextColor: colorsTheme.RewardSchemeTextColour,
        rewardSchemeStampBorderColor: colorsTheme.RewardSchemeStampBorderColour,
        rewardSchemePanelBackgroundColor:
          colorsTheme.RewardSchemePanelBackgroundColour,

        rewardSchemeTitle: rewardTheme.Title,
        rewardSchemeSubtitle: rewardTheme.SubTitle,
        rewardSchemePurchasesRequired: rewardTheme.PurchasesRequired,
        rewardSchemeTextColor: rewardTheme.TextColor,
        rewardSchemeStampIcon: rewardTheme.StampSVG,
        rewardSchemeStampBackgroundColor: rewardTheme.StampBackgroundColor,
        rewardSchemePanelBackgroundColor: rewardTheme.PanelBackgroundColor,
      });

      console.log(
        "Design Manager Initialized: ",
        designManager.getCurrentDesign()
      );
    } catch (error) {
      console.error("Error parsing theme responses:", error);
    }
  };

  if (companyDetails == null || loading == true) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const styles = {
    helpButton: {
      height: "45px",
      width: "45px",
      backgroundColor: "#0361fe",
      borderRadius: "14px",
      marginRight: "10px",
    },
    icon: {
      height: "45px",
      width: "45px",
      backgroundColor: "#0361fe",
      borderRadius: "14px",
    },
  };

  // Handle button click to open the submenu
  const handleClickNav = (event) => {
    setPopoverAnchorNav(event.currentTarget); // Set the anchor element to the clicked button
  };

  const handleIconClickHelp = (event) => {
    setPopoverAnchorHelp(event.currentTarget);
  };

  const handleCloseNav = () => {
    setPopoverAnchorNav(null); // Set popoverAnchor back to null to close
  };

  const handleCloseHelp = () => {
    setPopoverAnchorHelp(null);
  };

  const handleMenuItemClickHelp = (item) => {
    console.log(`${item} handleMenuItemClickHelp`);
    setActiveHelpItem(item); // Set which help topic is active
    setHelpOpen(true);
  };

  // Handle submenu item click
  const handleMenuItemClickNav = (item) => {
    //console.log(`${item} handleMenuItemClickNav`); // Log item clicked (replace with desired action)
    setActiveTab(item);
    handleCloseNav(); // Close the submenu
  };

  const closeHelp = () => {
    setHelpOpen(false);
    setPopoverAnchorHelp(null);
    setActiveHelpItem(null); // Reset the active help item
  };

  return (
    <Navbar name="My App">
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            height: isLargeScreen ? "800px" : "570px",
            width: isLargeScreen ? "388px" : "286px",
            position: "relative",
            marginLeft: width === "4%" ? "35%" : "20%",
            transition:
              "margin-left 0.1s ease, height 0.1s ease, width 0.1s ease",
            display: "flex",
            marginTop: "4%",
            justifyContent: "center",
            //backgroundColor: "#000000",
            alignItems: "center",
          }}
        >
          <img
            src={iPhoneImage}
            alt="iPhone"
            draggable="false" // Prevents dragging the image
            onDragStart={(e) => e.preventDefault()} // Prevents dragging via JavaScript
            style={{
              position: "absolute",
              width: "99%",
              height: "100%",
              objectFit: "contain",
              zIndex: 2,
              transition: "width 0.1s ease, height 0.1s ease",
              userSelect: "none", // Prevents selecting the image
              pointerEvents: "none",
            }}
          />
          <AppDesignRenderer
            activeTab={activeTab} // Use activeTab here
            setActiveTab={setActiveTab} // Pass setActiveTab to handle tab changes
            designManager={designManager}
            isLargeScreen={isLargeScreen}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "255px",
          }}
        >
          <IconButton style={styles.helpButton} onClick={handleIconClickHelp}>
            <span
              style={{
                color: "white",
                justifyContent: "center",
                fontSize: "24px", // Slightly smaller font size for a lighter look
                fontWeight: "lighter", // Keep this to maintain the thin style
                display: "flex", // Ensures centered alignment
                alignItems: "center",
              }}
            >
              ?
            </span>
          </IconButton>

          <Popover
            open={openHelp}
            anchorEl={popoverAnchorHelp}
            onClose={handleCloseHelp}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              marginTop: -2,
              marginLeft: "50px",
            }}
          >
            <List>
              <ListItem
                button
                onClick={() => handleMenuItemClickHelp("GettingStarted")}
              >
                <ListItemText primary="Getting Started" />
              </ListItem>
              <ListItem
                button
                onClick={() => handleMenuItemClickHelp("VideoGuide")}
              >
                <ListItemText primary="Video Guide" />
              </ListItem>
            </List>
          </Popover>

          {/* Conditionally Render Help Content */}
          {activeHelpItem === "GettingStarted" && (
            <GettingStarted isOpen={isHelpOpen} onClose={closeHelp} />
          )}
          {activeHelpItem === "VideoGuide" && (
            <VideoGuide isOpen={isHelpOpen} onClose={closeHelp} />
          )}

          <IconButton style={styles.helpButton} onClick={handleClickNav}>
            <img
              src={navigationIcon}
              alt="Navigation Icon"
              style={styles.icon}
            />
          </IconButton>

          <Popover
            open={openNav}
            anchorEl={popoverAnchorNav} // Updated to use popoverAnchor
            onClose={handleCloseNav}
            anchorOrigin={{
              vertical: "top", // Position the popover based on the top of the button
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom", // Transform origin to the bottom of the popover
              horizontal: "center",
            }}
            sx={{
              marginTop: -2, // This creates the gap
              marginLeft: "50px",
            }}
          >
            <List>
              <ListItem button onClick={() => handleMenuItemClickNav("splash")}>
                <ListItemText primary="Splash Screen" />
              </ListItem>
              <ListItem button onClick={() => handleMenuItemClickNav("login")}>
                <ListItemText primary="Login Screen" />
              </ListItem>
            </List>
          </Popover>
        </div>
        <DesignSidePanel
          fonts={availableFonts}
          onWidthChange={handleWidthChange}
          designManager={designManager}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </Navbar>
  );
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";

const CenteredDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
});

const WarningBox = ({ isOpen, onYes, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "5px", width: "460px" } }}
    >
      <DialogTitle style={{ paddingBottom: "5px", textAlign: "center" }}>
        Warning
      </DialogTitle>
      <DialogContent
        style={{
          paddingBottom: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center", // Ensure text is centered
        }}
      >
        You have unsaved changes. <br></br>
        <br></br> Do you wish you go back without saving?
      </DialogContent>
      <CenteredDialogActions
        style={{
          paddingBottom: "15px",
          alignItems: "center",
          justifyContent: "center",
          gap: "25%",
        }}
      >
        <Button
          onClick={onYes}
          color="primary"
          style={{ width: "30%", marginRight: "35px", borderRadius: "5px" }}
        >
          Yes
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          style={{ width: "30%", borderRadius: "5px" }}
        >
          No
        </Button>
      </CenteredDialogActions>
    </Dialog>
  );
};
export default WarningBox;

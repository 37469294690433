import React from "react";
import { Typography, Button } from "@mui/material";
import Base64Image from "../cards/sharedcomponents/Base64Image";

const LoginContent = ({ designManager }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "40px",
          display: "flex", // Enable Flexbox
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          backgroundColor: designManager.getFieldValue(
            "appHeaderBackgroundColor"
          ),
          textAlign: "center",
          marginTop: "15px",
          color: designManager.getFieldValue("appHeaderFontColor"),
          fontSize: "14px",
          fontFamily: designManager.getFieldValue("appHeaderFont"),
          zIndex: 2,
        }}
      >
        Login
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: designManager.getFieldValue(
            "primaryBackgroundColor"
          ),
          color: designManager.getFieldValue("primaryFontColor"),
          fontFamily: designManager.getFieldValue("primaryThemeFont"),
          display: "flex", // Flexbox for centering
          flexDirection: "column", // Arrange content vertically
          alignItems: "center", // Center horizontally
          justifyContent: "center", // Center vertically
        }}
      >
        <Base64Image
          image={designManager.getFieldValue("companyLogo")}
          fileType={designManager.getFieldValue("companyLogoFileType")}
          style={{
            width: "100%",
            maxWidth: "250px",
            height: "auto", // Ensures the aspect ratio is maintained
            maxHeight: "110px", // Limit the maximum height
            objectFit: "contain", // Ensures the image scales properly within the bounds
            marginBottom: "40px",
            marginTop: "-30px",
          }}
        />

        {/* Email Label */}
        <div
          style={{
            fontSize: "10px",
            color: designManager.getFieldValue("primaryFontColor"),
            alignSelf: "flex-start",
            paddingLeft: "40px",
            marginTop: "5%",
            paddingBottom: "5px",
            fontFamily: designManager.getFieldValue("primaryThemeFont"),
          }}
        >
          Email Address
        </div>

        {/* Email Input */}
        <input
          type="text"
          placeholder=""
          style={{
            width: "210px",
            height: "35px",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginBottom: "15px", // Added margin for spacing
          }}
        />

        {/* Password Label */}
        <div
          style={{
            fontSize: "10px",
            color: designManager.getFieldValue("primaryFontColor"),
            alignSelf: "flex-start",
            paddingLeft: "40px",
            marginTop: "1%",
            paddingBottom: "5px",
            fontFamily: designManager.getFieldValue("primaryThemeFont"),
          }}
        >
          Password
        </div>

        {/* Password Input */}
        <input
          type="password"
          placeholder=""
          style={{
            width: "210px",
            height: "35px",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginBottom: "15px", // Added margin for spacing
          }}
        />

        {/* Sign In Button */}
        <Button
          style={{
            height: "30px",
            backgroundColor: designManager.getFieldValue(
              "buttonBackgroundColor"
            ),
            color: designManager.getFieldValue("buttonFontColor"),
            width: "210px",
            borderRadius: "15px",
            marginTop: "5px",
            fontSize: "12px",
            fontFamily: designManager.getFieldValue("buttonsFont"),
          }}
        >
          Sign In
        </Button>

        {/* Register Link */}
        <div
          style={{
            fontSize: "10px",
            color: "#000000",
            textDecoration: "underline",
            marginTop: "15px",
            fontFamily: designManager.getFieldValue("primaryThemeFont"),
          }}
        >
          Don't have an Account? Register Here!
        </div>
      </div>
    </div>
  );
};

export default LoginContent;

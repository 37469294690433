import { ReactComponent as AppleJoystickWired } from "./wired/1472-apple-arcade-joistick.svg";
import { ReactComponent as AvatarUserWired } from "./wired/44-avatar-user-in-circle.svg";
import { ReactComponent as BalloonGlassWired } from "./wired/2999-balloon-glass.svg";
import { ReactComponent as BarberScissorsWired } from "./wired/1569-barber-scissors.svg";
import { ReactComponent as BasketballWired } from "./wired/432-basketball-ball.svg";
import { ReactComponent as BestSellerWired } from "./wired/1368-best-seller.svg";
import { ReactComponent as BeveragesWired } from "./wired/1910-beverages.svg";
import { ReactComponent as BicycleWired } from "./wired/488-bicycle.svg";
import { ReactComponent as BoomboxRadioWired } from "./wired/477-boombox-radio.svg";
import { ReactComponent as BowlOfSoupWired } from "./wired/2372-bowl-of-soup.svg";
import { ReactComponent as BrushWired } from "./wired/118-brush.svg";
import { ReactComponent as BurgerWired } from "./wired/1916-burger-alt.svg";
import { ReactComponent as ButterflyWired } from "./wired/1151-butterfly.svg";
import { ReactComponent as CasinoChipWired } from "./wired/1454-casino-gambling-chip.svg";
import { ReactComponent as CameraWired } from "./wired/61-camera.svg";
import { ReactComponent as CandyCaneWired } from "./wired/2230-candy-cane.svg";
import { ReactComponent as ChefKnifeWired } from "./wired/1606-professional-chef-knife.svg";
import { ReactComponent as ChristmasTreeWired } from "./wired/2232-christmas-tree.svg";
import { ReactComponent as CloakroomWired } from "./wired/1721-cloakroom.svg";
import { ReactComponent as CoffeeBeansWired } from "./wired/622-coffee-beans.svg";
import { ReactComponent as CoffeeTakeAwayWired } from "./wired/238-coffee-take-away.svg";
import { ReactComponent as CoffeeWired } from "./wired/239-coffee.svg";
import { ReactComponent as ComputerDisplayWired } from "./wired/478-computer-display.svg";
import { ReactComponent as ConfettiWired } from "./wired/1103-confetti.svg";
import { ReactComponent as CrownWired } from "./wired/407-crown-king-lord.svg";
import { ReactComponent as CupcakeHeartWired } from "./wired/3023-cupcake-heart.svg";
import { ReactComponent as Dog_WalkingWired } from "./wired/1164-dog-walking.svg";
import { ReactComponent as EarphoneWired } from "./wired/140-earphone-telephone.svg";
import { ReactComponent as EnvironmentEcoCareWired } from "./wired/437-environment-eco-care.svg";
import { ReactComponent as ExampleWired } from "./wired/1433-example.svg";
import { ReactComponent as F1_CarWired } from "./wired/890-f-1-car.svg";
import { ReactComponent as FishWired } from "./wired/522-fish.svg";
import { ReactComponent as FootballWired } from "./wired/431-football-soccer-ball.svg";
import { ReactComponent as GalaxyWired } from "./wired/1874-galaxy.svg";
import { ReactComponent as GiftWired } from "./wired/412-gift.svg";
import { ReactComponent as GolfWired } from "./wired/1815-golf.svg";
import { ReactComponent as GraduationWired } from "./wired/406-study-graduation.svg";
import { ReactComponent as GymWeightWired } from "./wired/429-weight-gym-fitness.svg";
import { ReactComponent as HairBrushWired } from "./wired/1591-hair-brush.svg";
import { ReactComponent as HairDryerWired } from "./wired/1570-hair-dryer.svg";
import { ReactComponent as HammerWired } from "./wired/1733-hammer.svg";
import { ReactComponent as HamsterWired } from "./wired/1188-hamster.svg";
import { ReactComponent as HandGiftWired } from "./wired/2968-hand-gift.svg";
import { ReactComponent as HeartWired } from "./wired/20-love-heart.svg";
import { ReactComponent as HomeWired } from "./wired/63-home.svg";
import { ReactComponent as HorseshoeWired } from "./wired/1456-horseshoe.svg";
import { ReactComponent as HorseWired } from "./wired/1169-trotting-horse.svg";
import { ReactComponent as Ice_CreamWired } from "./wired/549-ice-cream-scoops.svg";
import { ReactComponent as JewelleryWired } from "./wired/944-jewellery.svg";
import { ReactComponent as KettleWired } from "./wired/1756-kettle.svg";
import { ReactComponent as MagicWandWired } from "./wired/2844-magic-wand.svg";
import { ReactComponent as MakeupWired } from "./wired/1598-makeup.svg";
import { ReactComponent as MaleDoctorCareWired } from "./wired/671-male-doctor-care.svg";
import { ReactComponent as MargaritaWired } from "./wired/2993-margarita-glass.svg";
import { ReactComponent as MedicalInsuranceWired } from "./wired/1267-medical-insurancea.svg";
import { ReactComponent as Nail_PolishWired } from "./wired/1592-nail-polish.svg";
import { ReactComponent as PaintballWired } from "./wired/1900-paintball-gun.svg";
import { ReactComponent as PastePlateWired } from "./wired/603-plate-pasta.svg";
import { ReactComponent as PawsAnimalWired } from "./wired/448-paws-animal.svg";
import { ReactComponent as PedicureWired } from "./wired/1571-pedicure.svg";
import { ReactComponent as PingPongWired } from "./wired/1810-ping-pong-table-tennis.svg";
import { ReactComponent as PizzaWired } from "./wired/13-pizza.svg";
import { ReactComponent as RaisedHandWired } from "./wired/2527-rised-hand.svg";
import { ReactComponent as ScissorsWired } from "./wired/114-scissors.svg";
import { ReactComponent as SkateboardWired } from "./wired/1785-skateboard.svg";
import { ReactComponent as SkatesWired } from "./wired/2943-skates.svg";
import { ReactComponent as Smartphone_Wired } from "./wired/2805-smartphone-2.svg";
import { ReactComponent as SpaFlowerWired } from "./wired/1574-spa-flower.svg";
import { ReactComponent as SparklesWired } from "./wired/2474-sparkles-glitter.svg";
import { ReactComponent as StarRatingWired } from "./wired/237-star-rating.svg";
import { ReactComponent as StampWired } from "./wired/460-stamp.svg";
import { ReactComponent as StrawberryWired } from "./wired/578-strawberry.svg";
import { ReactComponent as TeddyBearWired } from "./wired/1546-teddy-bear-toy.svg";
import { ReactComponent as TreePlantingWired } from "./wired/1850-tree-planting.svg";
import { ReactComponent as TrebleClefWired } from "./wired/1497-treble-clef.svg";
import { ReactComponent as TrottingHorseWired } from "./wired/1169-trotting-horse.svg";
import { ReactComponent as UFOWired } from "./wired/492-ufo-aliens.svg";
import { ReactComponent as UmbrellaWired } from "./wired/422-umbrella.svg";
import { ReactComponent as WateringCanWired } from "./wired/1849-watering-can.svg";

//LINEAL ITEMS

import { ReactComponent as AppleArcadeJoystickLineal } from "./lineal/1472-apple-arcade-joistick-lineal.svg";
import { ReactComponent as BalloonGlassLineal } from "./lineal/2999-balloon-glass-lineal.svg";
import { ReactComponent as BarberScissorsLineal } from "./lineal/1569-barber-scissors-lineal.svg";
import { ReactComponent as BasketballBallLineal } from "./lineal/432-basketball-ball-lineal.svg";
import { ReactComponent as BestSellerLineal } from "./lineal/1368-best-seller-lineal.svg";
import { ReactComponent as BeveragesLineal } from "./lineal/1910-beverages-lineal.svg";
import { ReactComponent as BicycleLineal } from "./lineal/488-bicycle-lineal.svg";
import { ReactComponent as BoomboxRadioLineal } from "./lineal/477-boombox-radio-lineal.svg";
import { ReactComponent as BowlOfSoupLineal } from "./lineal/2372-bowl-of-soup-lineal.svg";
import { ReactComponent as BrushLineal } from "./lineal/118-brush-lineal.svg";
import { ReactComponent as BurgerAltLineal } from "./lineal/1916-burger-alt-lineal.svg";
import { ReactComponent as ButterFlyLineal } from "./lineal/1151-butterfly-lineal.svg";
import { ReactComponent as CameraLineal } from "./lineal/61-camera-lineal.svg";
import { ReactComponent as CandyCaneLineal } from "./lineal/2230-candy-cane-lineal.svg";
import { ReactComponent as CasinoGamblingChipLineal } from "./lineal/1454-casino-gambling-chip-lineal.svg";
import { ReactComponent as ChristmasTreeLineal } from "./lineal/2232-christmas-tree-lineal.svg";
import { ReactComponent as CloakroomLineal } from "./lineal/1721-cloakroom-lineal.svg";
import { ReactComponent as CoffeeBeansLineal } from "./lineal/622-coffee-beans-lineal.svg";
import { ReactComponent as CoffeeLineal } from "./lineal/239-coffee-lineal.svg";
import { ReactComponent as CoffeeToGoLineal } from "./lineal/238-coffee-take-away-lineal.svg";
import { ReactComponent as ComputerDisplayLineal } from "./lineal/478-computer-display-lineal.svg";
import { ReactComponent as ConfettiLineal } from "./lineal/1103-confetti-lineal.svg";
import { ReactComponent as CrownKingLordLineal } from "./lineal/407-crown-king-lord-lineal.svg";
import { ReactComponent as CupcakeHeartLineal } from "./lineal/3023-cupcake-heart-lineal.svg";
import { ReactComponent as DogWalkingLineal } from "./lineal/1164-dog-walking-lineal.svg";
import { ReactComponent as EarphoneLineal } from "./lineal/140-earphone-telephone-lineal.svg";
import { ReactComponent as EnvironmentEcoCareLineal } from "./lineal/437-environment-eco-care-lineal.svg";
import { ReactComponent as ExampleLineal } from "./lineal/1433-example-lineal.svg";
import { ReactComponent as F1CarLineal } from "./lineal/890-f-1-car-lineal.svg";
import { ReactComponent as FishLineal } from "./lineal/522-fish-lineal.svg";
import { ReactComponent as FootballSoccerBallLineal } from "./lineal/431-football-soccer-ball-lineal.svg";
import { ReactComponent as GalaxyLineal } from "./lineal/1874-galaxy-lineal.svg";
import { ReactComponent as GiftLineal } from "./lineal/412-gift-lineal.svg";
import { ReactComponent as GolfLineal } from "./lineal/1815-golf-lineal.svg";
import { ReactComponent as HairBrushLineal } from "./lineal/1591-hair-brush-lineal.svg";
import { ReactComponent as HairDryerLineal } from "./lineal/1570-hair-dryer-lineal.svg";
import { ReactComponent as HammerLineal } from "./lineal/1733-hammer-lineal.svg";
import { ReactComponent as HamsterLineal } from "./lineal/1188-hamster-lineal.svg";
import { ReactComponent as HandGiftLineal } from "./lineal/2968-hand-gift-lineal.svg";
import { ReactComponent as HomeLineal } from "./lineal/63-home-lineal.svg";
import { ReactComponent as HorseLineal } from "./lineal/1169-trotting-horse-lineal.svg";
import { ReactComponent as HorseshoeLineal } from "./lineal/1456-horseshoe-lineal.svg";
import { ReactComponent as IceCreamScoopsLineal } from "./lineal/549-ice-cream-scoops-lineal.svg";
import { ReactComponent as JewelleryLineal } from "./lineal/944-jewellery-lineal.svg";
import { ReactComponent as KettleLineal } from "./lineal/1756-kettle-lineal.svg";
import { ReactComponent as LoveHeartLineal } from "./lineal/20-love-heart-lineal.svg";
import { ReactComponent as MagicWandLineal } from "./lineal/2844-magic-wand-lineal.svg";
import { ReactComponent as MakeupLineal } from "./lineal/1598-makeup-lineal.svg";
import { ReactComponent as MaleDocatorCareLineal } from "./lineal/671-male-doctor-care-lineal.svg";
import { ReactComponent as MargaritaGlassLineal } from "./lineal/2993-margarita-glass-lineal.svg";
import { ReactComponent as MedicalInsuranceLineal } from "./lineal/1267-medical-insurancea-lineal.svg";
import { ReactComponent as NailPolishLineal } from "./lineal/1592-nail-polish-lineal.svg";
import { ReactComponent as PaintballGunLineal } from "./lineal/1900-paintball-gun-lineal.svg";
import { ReactComponent as PawsAnimalLineal } from "./lineal/448-paws-animal-lineal.svg";
import { ReactComponent as PedicureLineal } from "./lineal/1571-pedicure-lineal.svg";
import { ReactComponent as PingPongTableTennisLineal } from "./lineal/1810-ping-pong-table-tennis-lineal.svg";
import { ReactComponent as PizzaLineal } from "./lineal/13-pizza-lineal.svg";
import { ReactComponent as PlatePastaLineal } from "./lineal/603-plate-pasta-lineal.svg";
import { ReactComponent as ProfessionalChefKnifeLineal } from "./lineal/1606-professional-chef-knife-lineal.svg";
import { ReactComponent as RaisedHandLineal } from "./lineal/2527-rised-hand-lineal.svg";
import { ReactComponent as ScissorsLineal } from "./lineal/114-scissors-lineal.svg";
import { ReactComponent as SkatesLineal } from "./lineal/2943-skates-lineal.svg";
import { ReactComponent as SkateboardLineal } from "./lineal/1785-skateboard-lineal.svg";
import { ReactComponent as SparklesGlitterLineal } from "./lineal/2474-sparkles-glitter-lineal.svg";
import { ReactComponent as SpaFlowerLineal } from "./lineal/1574-spa-flower-lineal.svg";
import { ReactComponent as StarRatingLineal } from "./lineal/237-star-rating-lineal.svg";
import { ReactComponent as StampLineal } from "./lineal/460-stamp-lineal.svg";
import { ReactComponent as StrawberryLineal } from "./lineal/578-strawberry-lineal.svg";
import { ReactComponent as StudyGraduationLineal } from "./lineal/406-study-graduation-lineal.svg";
import { ReactComponent as TeddyBearToyLineal } from "./lineal/1546-teddy-bear-toy-lineal.svg";
import { ReactComponent as TreePlantingLineal } from "./lineal/1850-tree-planting-lineal.svg";
import { ReactComponent as TrebleclefLineal } from "./lineal/1497-treble-clef-lineal.svg";
import { ReactComponent as UfoAliensLineal } from "./lineal/492-ufo-aliens-lineal.svg";
import { ReactComponent as UmbrellaLineal } from "./lineal/422-umbrella-lineal.svg";
import { ReactComponent as UserAvatarLineal } from "./lineal/44-avatar-user-in-circle-lineal.svg";
import { ReactComponent as WateringCanLineal } from "./lineal/1849-watering-can-lineal.svg";
import { ReactComponent as WeightGymFitnessLineal } from "./lineal/429-weight-gym-fitness-lineal.svg";

export const stamps = {
  AppleJoystickWired,
  AvatarUserWired,
  BalloonGlassWired,
  BarberScissorsWired,
  BasketballWired,
  BestSellerWired,
  BeveragesWired,
  BicycleWired,
  BoomboxRadioWired,
  BowlOfSoupWired,
  BrushWired,
  BurgerWired,
  ButterflyWired,
  CasinoChipWired,
  CameraWired,
  CandyCaneWired,
  ChefKnifeWired,
  ChristmasTreeWired,
  CloakroomWired,
  CoffeeBeansWired,
  CoffeeTakeAwayWired,
  CoffeeWired,
  ComputerDisplayWired,
  ConfettiWired,
  CrownWired,
  CupcakeHeartWired,
  Dog_WalkingWired,
  EarphoneWired,
  EnvironmentEcoCareWired,
  ExampleWired,
  F1_CarWired,
  FishWired,
  FootballWired,
  GalaxyWired,
  GiftWired,
  GolfWired,
  GraduationWired,
  GymWeightWired,
  HairBrushWired,
  HairDryerWired,
  HammerWired,
  HamsterWired,
  HandGiftWired,
  HeartWired,
  HomeWired,
  HorseshoeWired,
  HorseWired,
  Ice_CreamWired,
  JewelleryWired,
  KettleWired,
  MagicWandWired,
  MakeupWired,
  MaleDoctorCareWired,
  MargaritaWired,
  MedicalInsuranceWired,
  Nail_PolishWired,
  PaintballWired,
  PastePlateWired,
  PawsAnimalWired,
  PedicureWired,
  PingPongWired,
  PizzaWired,
  RaisedHandWired,
  ScissorsWired,
  SkateboardWired,
  SkatesWired,
  Smartphone_Wired,
  SpaFlowerWired,
  SparklesWired,
  StarRatingWired,
  StampWired,
  StrawberryWired,
  TeddyBearWired,
  TreePlantingWired,
  TrebleClefWired,
  TrottingHorseWired,
  UFOWired,
  UmbrellaWired,
  WateringCanWired,

  AppleArcadeJoystickLineal,
  BalloonGlassLineal,
  BarberScissorsLineal,
  BasketballBallLineal,
  BestSellerLineal,
  BeveragesLineal,
  BicycleLineal,
  BoomboxRadioLineal,
  BowlOfSoupLineal,
  BrushLineal,
  BurgerAltLineal,
  ButterFlyLineal,
  CameraLineal,
  CandyCaneLineal,
  CasinoGamblingChipLineal,
  ChristmasTreeLineal,
  CloakroomLineal,
  CoffeeBeansLineal,
  CoffeeLineal,
  CoffeeToGoLineal,
  ComputerDisplayLineal,
  ConfettiLineal,
  CrownKingLordLineal,
  CupcakeHeartLineal,
  DogWalkingLineal,
  EarphoneLineal,
  EnvironmentEcoCareLineal,
  ExampleLineal,
  F1CarLineal,
  FishLineal,
  FootballSoccerBallLineal,
  GalaxyLineal,
  GiftLineal,
  GolfLineal,
  HairBrushLineal,
  HairDryerLineal,
  HammerLineal,
  HamsterLineal,
  HandGiftLineal,
  HomeLineal,
  HorseLineal,
  HorseshoeLineal,
  IceCreamScoopsLineal,
  JewelleryLineal,
  KettleLineal,
  LoveHeartLineal,
  MagicWandLineal,
  MakeupLineal,
  MaleDocatorCareLineal,
  MargaritaGlassLineal,
  MedicalInsuranceLineal,
  NailPolishLineal,
  PaintballGunLineal,
  PawsAnimalLineal,
  PedicureLineal,
  PingPongTableTennisLineal,
  PizzaLineal,
  PlatePastaLineal,
  ProfessionalChefKnifeLineal,
  RaisedHandLineal,
  ScissorsLineal,
  SkatesLineal,
  SkateboardLineal,
  SparklesGlitterLineal,
  SpaFlowerLineal,
  StarRatingLineal,
  StampLineal,
  StrawberryLineal,
  StudyGraduationLineal,
  TeddyBearToyLineal,
  TreePlantingLineal,
  TrebleclefLineal,
  UfoAliensLineal,
  UmbrellaLineal,
  UserAvatarLineal,
  WateringCanLineal,
  WeightGymFitnessLineal,
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logoSvg from "../tools/icons/logo.svg";
import "../theme/index.css";
import { ApiServiceClass } from "../components/api-service.js";
import ReCAPTCHA from "react-google-recaptcha";
import { Copyright } from "../components/copyright.js";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Register() {
  let navigate = useNavigate();
  const [tabNumber, setTabNumber] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [companyType, setCompanyType] = useState(0);
  const [textFields, setTextFields] = useState({
    forename: "",
    surname: "",
    email: "",
    password: "",
    password2: "",
    businessname: "",
    postcode: "",
    address1: "",
    address2: "",
    address3: "",
    websiteurl: "",
    facebookpagename: "",
    instagramusername: "",
  });

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const checkEmailUse = async () => {
    setLoading(true);
    try {
      const request = {
        EmailAddress: textFields.email,
      };
      const apiUrl = process.env.REACT_APP_API_BASE_URL + "/CheckEmailUse";
      const response = await axios.post(apiUrl, JSON.stringify(request), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        setLoading(false);
        return response.data;
      } else {
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error("Error during email check:", error);
      setLoading(false);
      return null;
    }
  };

  const handleCompleteUserDetails = async (event, newTabNumber) => {
    let isFormValid = true;
    setLoading(true);
    if (newTabNumber === 1) {
      try {
        let response = await checkEmailUse();
        console.log("emailInUse: ", response.EmailInUse);
        if (response.EmailInUse === true) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email is already in use",
          }));
          return false;
        }
      } catch (error) {
        console.error("Error checking email usage:", error);
      }
    }

    // Validate fields for the current tab
    if (tabNumber === 0) {
      // User Details Tab validation
      if (
        textFields.forename === "" ||
        textFields.email === "" ||
        textFields.password === "" ||
        textFields.password2 === ""
      ) {
        isFormValid = false;
        toast.error("Please fill in all required User Details fields", {
          autoClose: 1000,
        });
      } else if (
        errors.forename ||
        errors.email ||
        errors.password ||
        errors.password2
      ) {
        isFormValid = false;
        toast.error("Please resolve all errors in User Details fields", {
          autoClose: 1000,
        });
      }
    } else if (tabNumber === 1) {
      // Business Details Tab validation
      if (errors.businessname) {
        isFormValid = false;
        toast.error("Please resolve all errors in Business Details fields", {
          autoClose: 1000,
        });
      } else if (textFields.businessname === "" || textFields.postcode === "") {
        isFormValid = false;
        toast.error("Please fill in all required fields", { autoClose: 1000 });
      }
    } else if (tabNumber === 2) {
      // Social Media Tab validation
      if (!isChecked) {
        isFormValid = false;
        toast.error("Please agree to the terms and conditions", {
          autoClose: 1000,
        });
      } else if (!captchaValue) {
        isFormValid = false;
        toast.error("Please complete the CAPTCHA", { autoClose: 1000 });
      }
    }

    // Proceed to the next tab if validation passes
    if (isFormValid) {
      setTabNumber(newTabNumber);
    }
  };

  const previousTab = (event, newTabNumber) => {
    setTabNumber(newTabNumber);
  };

  const isBusinessDetailsInvalid = () => {
    var isFormValid = Object.values(errors).every((error) => error === "");
    if (!isFormValid) {
      toast.error("Please check all fields", { autoClose: 1000 });
      return true;
    }

    if (textFields.businessname == null || textFields.businessname === "")
      return true;
  };

  const validateField = (fieldName, value, validationRegex, errorMessage) => {
    // console.log('Validating ${fieldName} with value: ${value} and regex: ${validationRegex}');
    if (validationRegex && validationRegex.test) {
      if (!validationRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: errorMessage || "Invalid input",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: "",
        }));
      }
    } else {
      // If validationRegex is not provided or null, clear the error
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    }
  };

  const handleTextFieldChange =
    (fieldName, validationRegex, errorMessage) => (event) => {
      setTextFields((prevTextFields) => ({
        ...prevTextFields,
        [fieldName]: event.target.value,
      }));

      if (fieldName !== "password2")
        validateField(
          fieldName,
          event.target.value,
          validationRegex,
          errorMessage
        );
    };

  useEffect(() => {
    const passwordValue = textFields.password;
    const confirmPasswordValue = textFields.password2;

    if (passwordValue !== confirmPasswordValue && textFields.password2 !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password2: "",
      }));
    }
  }, [textFields.password, textFields.password2]);

  // Function to handle checkbox changes
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox status
  };

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      console.log("calling captcha: ", request);
      const response = await ApiServiceClass.VerifyCaptcha(request);
      if (response.status === 200) {
        console.log("Success: Captcha verified successfully");
        return true; // Return true indicating successful captcha verification
      } else {
        console.log("Error: Captcha verification failed");
        toast.error("Captcha verification failed");
        return false; // Return false indicating failed captcha verification
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      toast.error("Error during captcha verification");
      return false; // Return false indicating failed captcha verification due to error
    }
  };

  const handleRegister = async () => {
    var invalid = isBusinessDetailsInvalid();
    if (invalid) {
      return false;
    }
    var captcha = await verifyCaptcha();
    const addressString =
      `${textFields.address1}, ${textFields.address2}, ${textFields.address3}, ${textFields.postcode}`
        .replace(/,\s?,/g, ",")
        .trim();

    if (captcha && !invalid) {
      try {
        const payload = {
          FirstName: textFields.forename,
          EmailAddress: textFields.email,
          LastName: textFields.surname,
          Password: textFields.password,
          CompanyName: textFields.businessname,
          CompanyUrl: textFields.websiteurl,
          CompanyType: companyType < 1 ? 1 : companyType, // Set as other if unselected
          AddressInformation: addressString,
          Postcode: textFields.postcode,
          FacebookPageName: textFields.facebookpagename,
          InstagramUsername: textFields.instagramusername,
        };
        console.log(payload);
        const apiUrl = process.env.REACT_APP_API_BASE_URL + "/RegisterCompany";
        const response = await axios.post(apiUrl, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });
        if (response.status === 200) {
          navigate("/login", { state: { fromRegister: true } });
        } else {
          toast.error("Invalid username or password");
        }
      } catch (error) {
        if (error.message.includes("409")) {
          toast.error("Email is already in use");
        } else {
          toast.error("Error");
          console.error("Error:", error);
        }
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      className="background-container-wave"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2%",
      }}
    >
      {/* Top Left Logo */}
      <Link
        to="/home"
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          style={{ marginRight: "10px" }}
        >
          <image href={logoSvg} width="45" height="45" alt="Logo" />
        </svg>
        <span
          style={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "25px",
            color: "#0260FE",
            whiteSpace: "nowrap",
          }}
        >
          Reward Space
        </span>
      </Link>

      {/* Centered Register Box */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "550px",
            bgcolor: "#FFFFFF",
            border: "1px solid #2C3E50",
            borderRadius: "15px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Poppins-Medium",
              fontSize: "17px",
              marginBottom: "1rem",
            }}
          >
            Register Account
          </span>

          <Box sx={{ width: "100%", backgrounColor: "#444444" }}>
            <Box sx={{ borderColor: "divider" }}>
              <div className="tabsContainer">
                <Tabs
                  value={tabNumber}
                  onChange={isBusinessDetailsInvalid}
                  aria-label="Register Tabs"
                >
                  <Tab
                    className="tab"
                    name="Tab1"
                    label="User Details"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tab"
                    name="Tab2"
                    label="Business Details"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="tab"
                    name="Tab3"
                    label="Social Media"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </div>
            </Box>
          </Box>

          <CustomTabPanel
            value={tabNumber}
            index={0}
            sx={{ backgroundColor: "#444444" }}
          >
            <Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="forname"
                label="Forname"
                name="forname"
                autoFocus
                onChange={handleTextFieldChange(
                  "forename",
                  /^[a-zA-Z ]+$/,
                  "Invalid first name"
                )}
                value={textFields.forename}
                error={!!errors.forename}
                helperText={errors.forename}
              />
              <TextField
                margin="normal"
                fullWidth
                id="surname"
                label="Surname"
                name="Surname"
                onChange={handleTextFieldChange("surname", null, null)}
                value={textFields.surname}
                error={!!errors.surname}
                helperText={errors.surname}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={handleTextFieldChange(
                  "email",
                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  "Invalid email format"
                )}
                value={textFields.email}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleTextFieldChange(
                  "password",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Passwords must be atleast 8 characters with an uppercase"
                )}
                value={textFields.password}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm Password"
                type="password"
                onChange={handleTextFieldChange(
                  "password2",
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                  "Invalid password"
                )}
                value={textFields.password2}
                error={!!errors.password2}
                helperText={errors.password2}
              />
              <Button
                onClick={() => handleCompleteUserDetails(null, 1)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 2,
                  width: "50%",
                }}
              >
                Next
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "black",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel disabled value={tabNumber} index={1}>
            <Box>
              <TextField
                // margin="normal"
                required
                fullWidth
                name="businessname"
                label="Business name"
                type="businessname"
                id="businessname"
                onChange={handleTextFieldChange(
                  "businessname",
                  /.{2,}/,
                  "Invalid business name"
                )}
                value={textFields.businessname}
                error={!!errors.businessname}
                helperText={errors.businessname}
              />

              <Box
                display="flex"
                alignItems="left"
                marginBottom={1}
                marginTop={3}
                justifyContent="space-between"
              >
                {/* Dropdown */}
                <Select
                  value={companyType}
                  fullWidth
                  onChange={(e) => setCompanyType(e.target.value)}
                  style={{
                    minWidth: 150,
                    alignItems: "left",
                    textAlign: "left",
                  }} // Optional: Set a minimum width for the dropdown
                >
                  <MenuItem
                    value={0}
                    style={{ display: "none", textAlign: "left" }}
                  >
                    Select your business type
                  </MenuItem>
                  <MenuItem value={1}>Other</MenuItem>
                  <MenuItem value={5}>Construction</MenuItem>
                  <MenuItem value={4}>Education</MenuItem>
                  <MenuItem value={3}>Food and Drink</MenuItem>
                  <MenuItem value={2}>Glamour</MenuItem>
                  <MenuItem value={8}>Medical</MenuItem>
                  <MenuItem value={6}>Sanitation</MenuItem>
                  <MenuItem value={7}>Transportation</MenuItem>
                </Select>
              </Box>

              <div>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label="Enter Postcode"
                  variant="outlined"
                  name="postcode"
                  onChange={handleTextFieldChange(
                    "postcode",
                    /^[a-zA-Z0-9 ]{5,9}$/,
                    "Invalid postcode"
                  )}
                  value={textFields.postcode}
                  error={!!errors.postcode}
                  helperText={errors.postcode}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Address Line 1"
                  variant="outlined"
                  name="address1"
                  onChange={handleTextFieldChange(
                    "address1",
                    /.{2,}/,
                    "Address Line 1 must be at least 2 characters"
                  )}
                  value={textFields.address1}
                  error={!!errors.address1}
                  helperText={errors.address1}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Town/City"
                  variant="outlined"
                  name="address2"
                  onChange={handleTextFieldChange(
                    "address2",
                    /.{2,}/,
                    "Town/City must be at least 2 characters"
                  )}
                  value={textFields.address2}
                  error={!!errors.address2}
                  helperText={errors.address2}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="County"
                  variant="outlined"
                  name="address3"
                  onChange={handleTextFieldChange(
                    "address3",
                    /.{2,}/,
                    "County must be at least 2 characters"
                  )}
                  value={textFields.address3}
                  error={!!errors.address3}
                  helperText={errors.address3}
                />
              </div>

              <Button
                onClick={() => previousTab(null, 0)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 0,
                  ml: 2,
                  mr: 4,
                  width: "40%",
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => handleCompleteUserDetails(null, 2)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 0,
                  ml: 2,
                  mr: 2,
                  width: "40%",
                }}
              >
                Next
              </Button>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabNumber} index={2}>
            <Box>
              {/* <TextField
                margin="normal"
                fullWidth
                name="facebookpagename"
                label="Facebook Page Name"
                type="facebookpagename"
                id="facebookpagename"
                onChange={handleTextFieldChange("facebookpagename")}
                value={textFields.facebookpagename}
                error={!!errors.facebookpagename}
                helperText={errors.facebookpagename}
              /> */}

              {/* <TextField
                margin="normal"
                fullWidth
                name="instagramusername"
                label="Instagram Username"
                type="instagramusername"
                id="instagramusername"
                onChange={handleTextFieldChange("instagramusername")}
                value={textFields.instagramusername}
                error={!!errors.instagramusername}
                helperText={errors.instagramusername}
              /> */}

              <TextField
                margin="normal"
                fullWidth
                name="websiteurl"
                label="Website URL"
                type="websiteurl"
                id="websiteurl"
                onChange={handleTextFieldChange(
                  "websiteurl",
                  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*\/?$/,
                  "Invalid website url"
                )}
                value={textFields.websiteurl}
                error={!!errors.websiteurl}
                helperText={errors.websiteurl}
              />

              <label style={{ fontSize: "12px" }}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />{" "}
                I have read and agree to the{" "}
                <a
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleCheckboxChange}
                >
                  terms and conditions
                </a>
              </label>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4%",
              }}
            >
              <ReCAPTCHA
                sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              onClick={() => previousTab(null, 1)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                mb: 4,
                ml: 2,
                mr: 2,
                width: "40%",
              }}
            >
              Back
            </Button>
            <Button
              disabled={!isChecked || !captchaValue}
              type="submit"
              onClick={handleRegister}
              variant="contained"
              sx={{
                mt: 2,
                mb: 4,
                ml: 2,
                mr: 2,
                width: "40%",
              }}
            >
              Register
            </Button>
          </CustomTabPanel>
        </Box>
        {/* Bottom Copyright */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Copyright color="#FFFFFF" fontFamily="Poppins-Medium" />
        </Box>
      </Box>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { Check } from "@mui/icons-material"; // Check Icon from MUI Icons
import {
  LocalHospital,
  Dashboard,
  Hiking,
  ContentCut,
  Code,
  Factory,
  School,
  LocalFlorist,
  Coffee,
  Construction,
  DryCleaning,
  LocalShipping,
} from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

const ThemeSelector = ({ onThemeSelect, selectedTheme }) => {
  const [selectedCard, setSelectedCard] = useState();

  useEffect(() => {
    if (selectedTheme == null) {
      setSelectedCard(null);
    }
  }, [selectedTheme]);

  const handleCardSelect = (id) => {
    setSelectedCard(id);
    onThemeSelect(id); // Notify parent about the selected theme
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "15px",
    padding: "0px",
  };

  return (
    <div style={gridStyle}>
      {cardData.map((card) => (
        <Card
          key={card.id}
          {...card}
          isSelected={selectedCard === card.id}
          onSelect={handleCardSelect}
        />
      ))}
    </div>
  );
};

const cardData = [
  {
    id: 0,
    name: "Default",
    subtitle: "Default Subtitle",
    primaryColor: "#9E9E9E", // Neutral gray
    secondaryColor: "#E0E0E0", // Light gray for subtle contrast
    logo: Dashboard, // Neutral icon from MUI
    accentColor: "#BDBDBD", // Soft accent for a low-key look
    fontFamily: "Arial",
    primaryFontColor: "#616161", // Medium gray for readability
    secondaryFontColor: "#757575", // Slightly darker gray for secondary text
  },
  {
    id: 1,
    name: "Glamour",
    subtitle: "Glamour Subtitle",
    primaryColor: "#D36D94", // Soft pink
    secondaryColor: "#F4D2E6", // Blush pink
    logo: ContentCut, // Original icon
    accentColor: "#FF6F91", // Lighter accent color
    fontFamily: "Playfair Display",
    primaryFontColor: "#7A2C4C", // Darker shade for better readability
    secondaryFontColor: "#D36D94", // Matching secondary font with primary color
  },
  {
    id: 2,
    name: "Education",
    subtitle: "Education Subtitle",
    primaryColor: "#4A90E2", // Vibrant blue for freshness
    secondaryColor: "#E3F2FD", // Soft light blue for calm
    logo: School, // Original icon
    accentColor: "#FBC02D", // Bright yellow accent
    fontFamily: "Merriweather",
    primaryFontColor: "#0D47A1", // Dark blue for readability
    secondaryFontColor: "#1976D2", // Medium blue for secondary text
  },
  {
    id: 3,
    name: "Coffee",
    subtitle: "Coffee Subtitle",
    primaryColor: "#4F3025", // Rich coffee brown
    secondaryColor: "#F7EDDA", // Light cream for softness
    logo: Coffee, // Original icon
    accentColor: "#D19A00", // Warm amber accent
    fontFamily: "Raleway",
    primaryFontColor: "#3B241A", // Darker brown for readability
    secondaryFontColor: "#6F4C3E", // Matching secondary font with primary color
  },
  {
    id: 4,
    name: "Construction",
    subtitle: "Construction Subtitle",
    primaryColor: "#4A4E69", // Dark slate blue
    secondaryColor: "#E0E0E0", // Light soft gray
    logo: Construction, // Original icon
    accentColor: "#808080", // Medium gray accent
    fontFamily: "Quicksand",
    primaryFontColor: "#22223B", // Dark gray for readability
    secondaryFontColor: "#4A4E69", // Matching secondary font with primary color
  },
  {
    id: 5,
    name: "Cleaning",
    subtitle: "Cleaning Subtitle",
    primaryColor: "#00796B", // Deep teal
    secondaryColor: "#B2DFDB", // Soft mint green
    logo: DryCleaning, // Original icon
    accentColor: "#80CBC4", // Muted teal accent
    fontFamily: "DM Sans",
    primaryFontColor: "#003D33", // Darker teal for readability
    secondaryFontColor: "#00796B", // Matching secondary font with primary color
  },
  {
    id: 6,
    name: "Transportation",
    subtitle: "Transportation Subtitle",
    primaryColor: "#660066", // Deep purple
    secondaryColor: "#FFFFFF", // Clean white contrast
    logo: LocalShipping, // Original icon
    accentColor: "#450445", // Black for emphasis
    fontFamily: "Varela Round",
    primaryFontColor: "#660066", // Bold purple for readability
    secondaryFontColor: "#000000", // Matching secondary font with primary color
  },
  {
    id: 7,
    name: "Medical",
    subtitle: "Medical Subtitle",
    primaryColor: "#2C5282", // Deep blue - trustworthy, professional
    secondaryColor: "#E2E8F0", // Light cool gray - clean, clinical
    logo: LocalHospital,
    accentColor: "#4299E1", // Bright blue - engaging but professional
    fontFamily: "Montserrat",
    primaryFontColor: "#1A365D", // Darker blue for readability
    secondaryFontColor: "#2A4365", // Dark gray for secondary text
  },
];

const Card = ({
  id,
  name,
  subtitle,
  logo: Logo,
  primaryColor,
  secondaryColor,
  accentColor,
  primaryFontColor,
  secondaryFontColor,
  fontFamily,
  isSelected,
  onSelect,
}) => {
  const highlightColor = "#00cc00";

  const cardStyle = {
    width: "95%",
    height: "160px", // Increased height to accommodate font family display
    backgroundColor: secondaryColor,
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "12px",
    boxSizing: "border-box",
    position: "relative",
    cursor: "pointer",
    outline: isSelected ? `4px solid ${primaryColor}` : "none",
    fontFamily: fontFamily,
    transition: "all 0.1s ease-in-out",
    boxShadow: `0 4px 6px -1px ${primaryColor}20, 0 2px 4px -1px ${primaryColor}10`,
    border: `3px solid ${primaryColor}`,
  };

  const contentWrapperStyle = {
    flex: 1,
    display: "grid",
    gridTemplateRows: "45px 30px 25px 25px 1fr", // Fixed heights for each section
    alignItems: "center",
    gap: "4px",
  };

  const logoContainerStyle = {
    backgroundColor: primaryColor,
    borderRadius: "10px",
    padding: "6px",
    height: "40px",
    width: "40px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const titleStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    color: primaryFontColor,
    fontFamily: fontFamily,
    textAlign: "center",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const subtitleStyle = {
    fontSize: "14px",
    color: secondaryFontColor,
    fontFamily: fontFamily,
    textAlign: "center",
    marginTop: "-10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: "#000000"
  };

  const colorBoxesStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    padding: "4px",
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    borderRadius: "20px",
    marginTop: "-5px",
    width: "50%",
    marginLeft: "25%",
  };

  const colorBoxStyle = {
    minWidth: "20px",
    minHeight: "20px",
    border: "2px solid rgba(0, 0, 0, 1.0)",
    borderRadius: "50%",
  };

  return (
    <div style={cardStyle} onClick={() => onSelect(id)}>
      {isSelected && (
        <Check
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: highlightColor,
            fontSize: "30px",
          }}
        />
      )}
      <div style={contentWrapperStyle}>
        <div style={logoContainerStyle}>
          <Logo style={{ color: secondaryColor, fontSize: "28px" }} />
        </div>
        <h3 style={titleStyle}>{name}</h3>
        <p style={subtitleStyle}>{subtitle}</p>
        <div style={colorBoxesStyle}>
          <div
            style={{
              ...colorBoxStyle,
              backgroundColor: primaryColor,
              title: "Primary Color",
            }}
          />
          <div
            style={{
              ...colorBoxStyle,
              backgroundColor: secondaryColor,
              title: "Secondary Color",
            }}
          />
          <div
            style={{
              ...colorBoxStyle,
              backgroundColor: primaryFontColor,
              title: "Accent Color",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ThemeSelector;

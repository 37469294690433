import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { stamps } from "../../resources/svg/stamps";
import { renderToStaticMarkup } from "react-dom/server";

const NewSVG = ({ initialStamp, onStampChange }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [filteredStamps, setFilteredStamps] = useState([]);
  const [colorMap, setColorMap] = useState(new Map());
  const [showPicker, setShowPicker] = useState(false);
  const [activeColor, setActiveColor] = useState(null);
  const [pickerPosition, setPickerPosition] = useState({ left: 0, top: 0 });
  const pickerRef = useRef(null);
  const boxRefs = useRef({});
  const initialStampRef = useRef(initialStamp);
  const [stampBackground, setStampBackground] = useState(false); // Add a state for the result
  const [showWired, setShowWired] = useState(true); // State for wired checkbox
  const [showColoured, setShowColoured] = useState(true); // State for coloured checkbox
  const wiredCount = filteredStamps.filter(([key]) =>
    key.endsWith("Wired")
  ).length;
  const colouredCount = filteredStamps.filter(([key]) =>
    key.endsWith("Lineal")
  ).length;

  useEffect(() => {
    filterStamps();
    if (initialStamp && initialStampRef.current == initialStamp) {
      const decodedSvg = atob(initialStamp);
      console.log("Loaded initialStamp");
      setSelectedStamp(decodedSvg);
      setColorMap(generateDetectedColors(decodedSvg));
      setStampBackground(areAllColorsNearWhite(colorMap));
      initialStampRef.current = initialStamp;
    }
  }, [initialStamp]);

  const filterStamps = () => {
    const filtered = Object.entries(stamps).filter(([key]) => {
      const isLineal = key.endsWith("Lineal");
      const isWired = key.endsWith("Wired");
      return (showWired && isWired) || (showColoured && isLineal);
    });
    setFilteredStamps(filtered);
  };
  useEffect(() => {
    filterStamps(); // Apply filtering whenever checkbox states change
  }, [showWired, showColoured]); // Add showWired and showColoured as dependencies

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        !Object.values(boxRefs.current).some((ref) =>
          ref?.contains(event.target)
        )
      ) {
        setShowPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    setStampBackground(areAllColorsNearWhite(colorMap));
  }, [colorMap]);

  const detectColors = (svgString) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
    const colors = new Map();

    const processColor = (color, attr, element) => {
      if (color && color !== "none") {
        if (!colors.has(color)) {
          colors.set(color, color);
        }
      }
    };

    svgDoc.querySelectorAll("[stroke], [fill]").forEach((element) => {
      processColor(element.getAttribute("stroke"), "stroke", element);
      processColor(element.getAttribute("fill"), "fill", element);
    });
    console.log("Detected colours: ", colors);
    return colors;
  };

  const generateDetectedColors = (svgString) => {
    console.log("generateDetectedColors");

    // Parse the raw SVG string into an SVG document
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElement = svgDoc.querySelector("svg");

    const detectedColors = new Map();

    if (svgElement) {
      // Query all elements with potential fill or stroke attributes
      const elements = svgElement.querySelectorAll("[fill], [stroke]");

      elements.forEach((element) => {
        const fill = element.getAttribute("fill");
        const stroke = element.getAttribute("stroke");

        // Skip any element with fill="red"
        if (fill && fill.toLowerCase() === "red") {
          return; // Skip this element entirely
        }

        // Add valid colors to the map
        if (fill && fill !== "none" && fill.toLowerCase() !== "#fff") {
          detectedColors.set(fill, fill);
        }
        if (stroke && stroke !== "none" && stroke.toLowerCase() !== "#fff") {
          detectedColors.set(stroke, stroke);
        }
      });
    }
    console.log("Detected colours: ", detectedColors);
    if (!detectedColors || detectedColors.size === 0) {
      detectedColors.set("default", "black"); // Set default color as black
    }
    return detectedColors;
  };

  const handleStampChange = (svgString) => {
    console.log("handleStampChange");
    setSelectedStamp(svgString);
    setColorMap(null);
    setColorMap(generateDetectedColors(svgString));
    setOpen(false);
    const encodedSvg = btoa(unescape(encodeURIComponent(svgString)));
    // console.log("Stamp changed encoded: ", encodedSvg);
    onStampChange(encodedSvg);
  };

  const handleColorChange = (color) => {
    if (activeColor) {
      const newColorMap = new Map(colorMap);
      newColorMap.set(activeColor, color.hex);

      const modifiedSvg = applyColors(selectedStamp, newColorMap);
      // Only update state and trigger re-render once
      setColorMap(newColorMap);
      onStampChange(btoa(modifiedSvg));
    }
  };

  const handleBoxClick = (color, boxRef) => {
    // Set active color and show picker without redundant toggles
    if (activeColor === color && showPicker) {
      return; // No need to reinitialize if already active
    }

    setActiveColor(color);
    const rect = boxRef.getBoundingClientRect();
    setPickerPosition({
      left: Math.min(rect.right + 20, window.innerWidth - 250),
      top: Math.min(rect.top - 100, window.innerHeight - 350),
    });
    setShowPicker(true);
  };
  const applyColors = (svgString, colors) => {
    let modified = svgString;
    colors.forEach((newColor, originalColor) => {
      const patterns = [
        [`fill="${originalColor}"`, `fill="${newColor}"`],
        [`stroke="${originalColor}"`, `stroke="${newColor}"`],
        [`(fill|stroke):\\s*${originalColor}`, `$1: ${newColor}`],
      ];
      patterns.forEach(([pattern, replacement]) => {
        modified = modified.replace(new RegExp(pattern, "g"), replacement);
      });
    });
    console.log("Applying colours: ", colors);
    return modified;
  };

  const renderSVG = () => {
    if (!selectedStamp) return null;

    let modifiedSvg = selectedStamp;
    colorMap.forEach((newColor, originalColor) => {
      const regex = new RegExp(`(stroke|fill)="${originalColor}"`, "g");
      modifiedSvg = modifiedSvg.replace(regex, `$1="${newColor}"`);
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: modifiedSvg.replace(
            "<svg",
            '<svg preserveAspectRatio="xMidYMid meet" width="100%" height="100%" style="object-fit: contain;"'
          ),
        }}
      />
    );
  };

  const isNearWhite = (color, threshold = 200) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const brightness = (r + g + b) / 3;

    return brightness > threshold;
  };

  const areAllColorsNearWhite = (colorMap, threshold = 200) => {
    for (const color of colorMap.values()) {
      if (!isNearWhite(color, threshold)) {
        return false; // Return false as soon as one color isn't near white
      }
    }
    return true; // All colors passed the check
  };

  return (
    <Box sx={{ width: "90%", marginTop: "5px" }}>
      <Paper
        elevation={0}
        onClick={() => setOpen(true)}
        sx={{
          cursor: "pointer",
          p: 2,
          border: "2px dashed",
          backgroundColor: stampBackground ? "#595858" : "grey.100", // Use the state here
          borderColor: "grey.300",
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": { borderColor: "grey.400" },
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedStamp ? (
            renderSVG()
          ) : (
            <Typography color="textSecondary">Select a Stamp</Typography>
          )}
        </Box>
      </Paper>

      {selectedStamp && colorMap.size > 0 && (
        <Box sx={{ display: "flex", mt: 2, gap: 1, justifyContent: "center" }}>
          {Array.from(colorMap.entries()).map(
            ([originalColor, currentColor]) => (
              <Box
                key={originalColor}
                ref={(el) => (boxRefs.current[originalColor] = el)}
                sx={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: currentColor,
                  border: "2px solid", // Ensure the border style is set
                  borderColor: stampBackground ? "grey.700" : "transparent", // Conditionally apply color
                  borderRadius: "6px",
                  cursor: "pointer",
                  "&:hover": { transform: "scale(1.1)" },
                }}
                onClick={(e) => {
                  setShowPicker(false);
                  setTimeout(() => {
                    setActiveColor(originalColor);
                    const rect = e.target.getBoundingClientRect();
                    setPickerPosition({
                      left: Math.min(rect.right + 20, window.innerWidth - 250),
                      top: Math.min(rect.top - 100, window.innerHeight - 350),
                    });
                    setShowPicker(true);
                  }, 10);
                }}
              />
            )
          )}
        </Box>
      )}

      {showPicker && (
        <div
          ref={pickerRef}
          style={{
            position: "fixed",
            left: pickerPosition.left,
            top: pickerPosition.top,
            zIndex: 9999,
          }}
        >
          <SketchPicker
            color={colorMap.get(activeColor)}
            onChange={handleColorChange}
          />
        </div>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Select a Stamp</DialogTitle>
        {/* Add checkboxes to filter wired and coloured stamps */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            marginLeft: "25px",
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={showWired}
              onChange={() => setShowWired((prev) => !prev)}
            />
            <span style={{ marginLeft: "5px" }}>
              Show Wired Icons ({wiredCount})
            </span>
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={showColoured}
              onChange={() => setShowColoured((prev) => !prev)}
            />
            <span style={{ marginLeft: "5px" }}>
              Show Coloured Icons ({colouredCount})
            </span>
          </label>
        </Box>
        <DialogContent
          sx={{
            minHeight: "150px",
            padding: "3%", // Adjust the value as needed
          }}
        >
          <TextField
            label="Search"
            fullWidth
            value={searchTerm}
            onChange={(e) => {
              const term = e.target.value.toLowerCase();
              setSearchTerm(e.target.value);
              setFilteredStamps(
                Object.entries(stamps).filter(([key]) =>
                  key.toLowerCase().includes(term)
                )
              );
            }}
            sx={{ mb: 2, mt: 0 }}
          />
          <Grid container spacing={4}>
            {filteredStamps.map(([key, StampComponent]) => (
              <Grid item xs={3} sm={2} key={key}>
                <MenuItem
                  onClick={() => {
                    const svgString = renderToStaticMarkup(<StampComponent />);
                    handleStampChange(svgString);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 1,
                    height: "120px",
                  }}
                >
                  <StampComponent
                    viewBox="0 0 430 430"
                    preserveAspectRatio="xMidYMid meet"
                    width="100%"
                    height="100%"
                  />
                  <div style={{ marginTop: "15px" }}>
                    {key.replace(/(Wired|Lineal)$/, "").replace(/_/g, " ")}
                  </div>
                </MenuItem>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewSVG;

import React from "react";

const Base64Image = ({ image, fileType, altText = "Image", style = {} }) => {
  const fileTypeMapping = {
    "image/svg+xml": 1, // SVG MIME type -> 1
    "image/jpeg": 2, // JPEG MIME type -> 2
    "image/png": 3, // PNG MIME type -> 3
    "image/webp": 4, // WebP MIME type -> 4
  };

  const reverseFileTypeMapping = Object.fromEntries(
    Object.entries(fileTypeMapping).map(([key, value]) => [value, key])
  );

  // Check if the base64 string is valid
  if (!image) {
    return <p>No image provided.</p>;
  }
  const mimeType = reverseFileTypeMapping[fileType] || "image/png"; // Default to 'image/png' if not found
  const imageUrl = `data:${mimeType};base64,${image}`; // Create preview URL for base64 image
  console.log("image url: ", imageUrl);

  return (
    <img
      src={imageUrl}
      alt={altText}
      style={{ maxWidth: "90%", maxHeight: "90%", ...style }}
    />
  );
};

export default Base64Image;
